.detailNews{
    position: relative;
    bottom: 240px;
    width: 80%;
    margin-left: 10%;

}

.detailMainImage{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-height: 100vh;
}
.aboutDetailDivider{
    width: 100%;
    height: 50vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.detailNews > .textPartNews > h2{
    font-size: 60px;
    padding-top: 42px;
    margin-left: 7.7%;
    width: 100%;
}
.detailNews > .textPartNews{
    max-width: 90%;
    
}
.detailNews > .textPartNews > h5{
    margin-left: 8%;
}
.detailNews > .textPartNews > p{
    padding-top: 30px;
    margin: 0 8%;
    width: 90%;
}
.detailNews > .textPartNews > div{
    padding-top: 30px;
    margin: 0 8%;
    width: 90%;
}

@media only screen and (max-width: 1400px){


    .detailNews > .textPartNews > h2{
        font-size: 50px;
    }
    .detailNews > .textPartNews > p{
        width: 76%;
    }
	.detailNews > .textPartNews > div{
        width: 76%;
    }
    .detailNews > .textPartNews > a{
        border: 3px solid white;
    }


}

@media only screen and (max-width: 1000px){
    .detailNews > .textPartNews > h2{
        font-size: 30px;
    }
    .detailNews > .textPartNews > p{
        width: 70%;
    }
	.detailNews > .textPartNews > div{
        width: 70%;
    }
}

@media only screen and (max-width: 600px){
    .detailNews > .textPartNews{
        margin-top: 50%;
    }
    .detailNews > .textPartNews > h2{
        margin-left: 30px;
        font-size: 30px;
    }
    .detailNews > .textPartNews > h5{
        margin-left: 30px;
    }
    .detailNews > .textPartNews > p{
        margin: 0 auto;
        width: 80%;
    }
	.detailNews > .textPartNews > div{
        margin: 0 auto;
        width: 80%;
    }
}