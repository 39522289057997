.launchScreen{
    /*background-image: url(../../img/sample_render.jpg);*/
    width: 100%;
}
.mainContent{
	padding-top: 7em;
	margin-left: 12%;
	max-width: 90%;
}
.mainContent > h2{
    font-size: 40px;
    max-width: 80%;
}
.mainContent > p{
    font-size: 25px;
    margin-top: 4%;
    width: 70%;
}


.newsSnippet{
    display: flex;
    justify-content: center;
    padding: 5% 0;
    /*margin-left: 5%;*/
    flex-wrap: wrap;
}

.moreCenter {
	text-align: center;
}

.eachNewsSnippet {
	overflow: hidden;
	overflow-wrap: break-word;
}

.eachNewsSnippet > img{
	width: 10%;
}
.eachNewsSnippet > hr{
    width: 70%;
    border-width: 1px;
    opacity: 40%;
}
.eachNewsSnippet > h2{
    padding-top: 5%;
    padding-bottom: 2%;
}
.eachNewsSnippet > p{
    font-size: 20px;
    font-weight: 500;
}
.eachNewsSnippet > a{
    /*float: right;
    width: 50%;*/
    padding-top: 1%;
}
/*MOBILE*/
@media only screen and (max-width: 1000px){
    .mainContent{
        width: 100%;
        padding-top: 5.5em;
        margin-left: 0%;
        }
    .mainContent > h2{
            padding-top: 5%;
            padding-bottom: 2%;
            margin-left: 10%;
            width: 90%;
            font-size: 25px;
        }
    .mainContent > p{
            font-size: 20px;
            font-weight: 500;
            margin-left: 10%;
            width: 80%;
        }
    .icons{
        margin-top: 1%;
    }
    .mouse{
        width: 4%;
    }

        .newsSnippet{
            display: flex;
            justify-content: center;
            padding: 5% 0;
            margin-left: 5%;
            
        }
       
        .eachNewsSnippet > img{
        width: 10%;
        }
        .eachNewsSnippet > hr{
            max-width: 70%;
            border-width: 1px;
            opacity: 40%;
        }
        .eachNewsSnippet > h2{
            padding-top: 5%;
            padding-bottom: 2%;
            font-size: 20px;
            max-width: 90%;
        }
        .eachNewsSnippet > p{
            font-size: 15px;
            font-weight: 500;
            max-width: 60%;
        }
        .eachNewsSnippet > a{
            /*float: right;
            width: 50%;*/
            padding-top: 1%;
            font-size: 15px;
        }
}
@media only screen and (max-width: 600px){

    .newsSnippet{
        display: block;
        margin-left: 15%;
		margin-right: 15%;
    }

    .mainContent{
        margin-left: 5%;
    }

}

@media only screen and (max-width: 400px){
	.logoText {
		display: none;
	}
    
	.logo {
		margin-left: 25px;
	}
}