.detailProject{
    position: relative;
    bottom: 330px;
    width: 80%;
    margin-left: 10%;
}
.detailMainImage{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-height: 100vh;
}
.detailProject > .textPart > h2{
    font-size: 80px;
    padding-top: 42px;
    margin-left: 103px;
    width: 60%;
}
.detailProject > .textPart > .icons{
    margin-left: 103px;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 50px;
    float: none;
}

.detailProject > .textPart > a{
    float: right;
    margin-right: 10%;
    padding: .8vw 0;
    width: 18%;   

    border: 5px solid white;
    border-radius: 6px;

    text-align: center;
    text-decoration: none;
    font-size: 1.5vw;
   
}
.detailProject > .textPart > p{
    margin: 0 auto;
    width: 86%;
}

@media only screen and (max-width: 1400px){


    .detailProject > .textPart > h2{
        font-size: 60px;
    }
    .detailProject > .textPart > a{
        border: 3px solid white;
    }

}

.each-slide-detail > div{
    height: 70vh;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 50px;
    background-position: center;
    background-repeat: none;
    background-size: cover;
 
}

.react-slideshow-container > .nav{
    background: none;

} 
.detailProject .nav:first-of-type{
    position: relative;
    left: 5%;
}
.detailProject  .nav:last-of-type{
    position: relative;
    right: 5%;
}
@media only screen and (max-width: 1200px){
    .detailProject{
        bottom: 200px;
    }
    .each-slide-detail > div{
        height: 50vw;
    }
}

@media only screen and (max-width: 1000px){
    .detailProject{
        bottom: 100px;
    }
    .detailProject > .textPart > h2{
        font-size: 40px;
    }
    .detailProject > .textPart > a{
        position: block;
    }
    .each-slide-detail > div{
        height: 50vw;
    }
}
@media only screen and (max-width: 790px){
    .detailProject > .textPart > a{
  
        left: 16%;
    }
}
@media only screen and (max-width: 700px){
    .detailProject > .textPart > a{
       
        left: 19%;
    }
}
@media only screen and (max-width: 600px){
    .detailProject{
        bottom: 100px;
    }
  
    .detailProject > .textPart > h2{
        margin-left: 30px;
        font-size: 25px;
        width: 100%;
    }
    .detailProject > .textPart > a{
       
        float: right;
        margin-right: 10%;
        margin-top: 15px;
        padding: 2.2vw 20px;
        width: 18%;   
    
        border: 3px solid white;
        border-radius: 6px;
    
        text-align: center;
        text-decoration: none;
        font-size: 10px;
    }
    .detailProject > .textPart > .icons{
        margin-left: 30px;
        padding-top: 10px;
        margin-bottom: 70px;
    }
    .detailProject > .textPart > p{
        margin: 0 auto;
        margin-top: 10px;
        margin-left: 30px;
        width: 80%;
    }
    .each-slide-detail > div{
        height: 40vw;
    }
}