
    .aboutUs{
        margin-left: 10%;
    }
    .aboutUs > .intro > h1{
    padding-top: 120px;
    padding-bottom: 50px;
    font-size: 40px;
    font-weight: normal;
    }
    .aboutUs > .intro > p{
        width: 90%;
    }
    .eachManager > img{
        -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.44);
        -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.44);
        box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.44);
        margin-top: 50px;
        width: 85%;
    }
    .eachManager > h2{
        margin-top: 40px;
        font-size: 25px;
        font-weight: 500;
    }
    .eachManager > h3{
        font-size: 20px;
        font-weight: normal;
        width: 45%;
        float: left;
    }
    .eachManager > p{
        width: 90%;
        margin-bottom: 50px;
        
    }

    @media only screen and (min-width: 1000px){
        .aboutUs{
            display: flex;
            justify-content: start;
            margin-top: 150px;
        }
        .aboutUs > .intro > p{
            width: 80%;
        }
        .eachManager > img{
            width: 70%;
        }
        .eachManager > h3{
            width: 30%;
        }
        .eachManager > p{
            width: 80%;
            margin-bottom: 100px;
            
        }
    }
    @media only screen and (min-width: 1500px){
        .aboutUs{
            display: flex;
            justify-content: start;
            margin-top: 150px;
        }
        .aboutUs > .intro > p{
            width: 60%;
        }
        .eachManager > img{
            width: 55%;
        }
        .eachManager > h3{
            width: 30%;
        }
        .eachManager > p{
            width: 70%;
            margin-bottom: 100px;
            
        }
    }

