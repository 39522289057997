
  
.slide-container {
    width: 100%;
    height: 100%;
    margin: auto; 
}
.each-slide{
    width: 100%;
}
.each-slide > div {
    width: 100%;
    height: 980px;
    background-position: center;
    background-repeat: no-repeat;
    
    background-size: cover;
    height: 100%;
    margin: 0;
  }


.slideText{
    width: 100%;
    padding-top: 31%;
    margin-left: 10%;

    
}
.slideHeadline{
    font-size: 100px;
    font-weight: bold;
    margin: 0;
    width: 100%;

}
.newBanner{
    position: absolute;
    right: 60.25%;
    top: 60%;
    
  
}
.slideParagraph{
    margin: 0;
    font-size: 25px;
    width: 60%;
    


}
.icons{
    float: left;
    width: 100%;
    margin-left: 9.5%;
}
.icons > img{
    width: 2%;
    margin-left: 0.5%;
}

.spacer{
    margin-left: 49%;
    padding-bottom: 11%;
}
.mouse{
    margin-left: 49%;
    position: relative;
    bottom: 100px;
}



@media only screen and (max-width: 1000px){
    .slide-container {
        width: 100%;
        height: 100%;
        margin: 0; 
    }
    .each-slide > div {
        
        background-size: cover;
        height: 100%;
        margin: 0;

      
    }
    .slideText{
        width: 100%;
        padding-top: 30%;
        margin-left: 10%;

    
    }
    .slideHeadline{
        font-size: 50px;
        font-weight: bold;
        margin: 0;
        width: 100%;

    }
    .newBanner{
        right: 61.6%;
        top: 50%;
        width: 2%;
    }
    .slideParagraph{
        font-size: 15px;
    }
    .icons > img{
        width: 3%;
    }
}
@media only screen and (max-width: 600px){
    .slideHeadline{
        font-size: 30px;
    }
    .slideParagraph{
        font-size: 10px;
        width: 80%;
    }
    .icons >img{
        width: 4%;
    }
    .mouse{
        width: 5%;
    }
}
 