.socialMediaIcons{
    text-align: right;
    padding-right: 5%;
}
.socialMediaIcons >a>img{
    width: 2%;
    margin-left: 34.6px;
}

@media only screen and (max-width: 700px){
    .socialMediaIcons{
        text-align: left;
        padding-left: 5%;
        margin: 10% 0;
       
    }
    .socialMediaIcons >a>img{
        width: 8%;
        margin-left: 34.6px;
    }
}