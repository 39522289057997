
    .socialMediaIconsAboutUs{
        text-align: left;
        padding-left: 5%;
        margin-bottom: 10%;
        margin-right: 10%;
        float: left;
        width: 45%;
       
    }
    .socialMediaIconsAboutUs >a>img{
        width: 10%;
        margin-left: 9px;
    }
    @media only screen and (min-width: 1000px){
        .socialMediaIconsAboutUs{
            
        }
    }