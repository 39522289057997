* {
margin: 0;
padding: 0;
  font-family: 'Roboto', sans-serif;
}
html {
  font-size: 16px;
}

body {
  background-color: #242424;
  color: white;
}

a {
  color: white;
}

li {
  margin-left: 1em;
}