
.logoText{
    /*float: left;
    width: auto;
    margin-left: 7.2%;
    padding-top: 30px;*/
    font-size: 30px;
	display: table-cell;

	vertical-align: middle;
}
.logo{
    /*float: left;
    width: 4%;
	margin-top: 0.55em;
    padding-top: 30px;*/
	width: 3em;
	margin-top: -0.5em;
	display: table-cell;
	vertical-align: middle;
}
.header{
    z-index: 99999999;
    position: absolute;
    /*text-align: right;
    height: 70px;*/
    line-height: 70px;
    top: 0;
	width: 100%;
	background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0,0,0,0) 100%);
	padding-top: 15px;
	padding-bottom: 30px;
	display: table;
}
#menuWrapper {
	display: table-cell;
	text-align: right;
	vertical-align: middle;
}

.menu{
    margin-right: 7.2%;
    /*width: auto;*/
}
.menu a{
    clear: right;
    text-decoration: none;
    margin-left: 5%;
    font-size: 20px;
}

.navTag {
	display: table;
	margin-top: 15px;
	margin-left: 7.2%;
	text-decoration: none;
	margin-bottom: 10px;
}

label{
    margin: 0 40px 0 0;
    font-size: 26px;
    line-height: 70px;
    display: none;
    float: right;
}

#toggle{
    display: none;
}

/*MOBILE*/
@media only screen and (max-width: 1000px){

    .logoText{
        float: left;
        /*width: auto;
        margin-left: 7.2%;
        padding-top: 15px;*/
        font-size: 25px;
    }
    .logo{
        /*float: left;
        
        padding-top: 30px;*/
		width: 2.5em;
		margin-top: 0em;
    }
    /*Hamburger menu*/
    label{
        display: block;
        cursor: pointer;
        width: auto;
        /*padding-top: 15px;*/
    }
    .menu{
        text-align: center;
        width: 100%;
        display: none;
        background-color: #242424;
        -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
        -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
        box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
    }
    .menu a{
        display: block;
    }
    #toggle:checked + .menu{
        display: block;

    }
}