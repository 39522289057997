
.projects{
    padding: 0 60px;
}
.projects > h1{
    padding-top: 200px;
    padding-bottom: 87px;
    font-size: 50px;
    font-weight: normal;
    
}

.eachProject{
    -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
    -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
   
    
    display: flex;
    margin-bottom: 10%;
   
}
.imagePart{
    
    margin: 5%;
    width: 45%;
    height: 400%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.detailLink{
    text-decoration: none;
}
.eachProject > .imagePart > .icons{
    margin-top: 56%;
    margin-left: 2%;
}
.eachProject > .imagePart > .icons >img{
    width: 5%;
}
.textPart{
    margin-top: 5%;
    width: auto;
}
.textPart > h2{
font-size: 50px;
text-decoration: none;
}
.textPart > p{
    font-size: 20px;
    width: 80%;
    margin-top: 44px;
    text-decoration: none;
    padding-bottom: 10%;
}
a > .eachProject > .textPart >h2{
    text-decoration: none;
}

@media only screen and (max-width: 1600px){
    .textPart > p{
        font-size: 20px;
    }
}
@media only screen and (max-width: 1430px){
    .textPart > h2{
        font-size: 60px;
        }
        .textPart > p{
            font-size: 15px;
        }
}
@media only screen and (max-width: 1000px){
    .eachProject{
        display: block;
        padding: 10%;
    }
    .imagePart{    
        margin: 0;
        width: 100%;
        height: 40vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .textPart > p{
            font-size: 20px;
            width: 100%;
    }
    .eachProject > .imagePart > .icons{
        margin-top: 50%;
        margin-left: 2%;
    }
}
@media only screen and (max-width: 700px){
        .projects > h1{
        padding-top: 120px;
        padding-bottom: 50px;
        font-size: 40px;
        font-weight: normal;
        
        }
        .textPart > h2{
            margin-top: 10%;
            font-size: 30px;
            }
        .textPart > p{
            margin-top: 22px;
                font-size: 15px;
                width: 100%;
        }
        .projects{
            padding: 0 30px;
        }
    
}