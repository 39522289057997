
.news{
    padding: 0 60px;
}
.news > h1{
    padding-top: 200px;
    padding-bottom: 87px;
    font-size: 50px;
    font-weight: normal;
    
}

.eachNews{
    -webkit-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
    -moz-box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.7);
   
    background-color: #2B2B2B;
    display: flex;
    margin-bottom: 10%;
   
}
.imagePartNews{
    
    margin: 5%;
    width: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.noImagePartNews{
    max-width: 20%;
}
.detailLink{
    text-decoration: none;
}
.eachNews > .imagePartNews > .icons{
    margin-top: 56%;
    margin-left: 2%;
}
.eachNews > .imagePartNews > .icons >img{
    width: 5%;
}
.textPartNews{
    margin-top: 5%;
    width: auto;
    max-width: 40%;
	padding-bottom: 5%;
}

.textPartNews> h2{
	font-size: 50px;
	text-decoration: none;
}
.textPartNews > p{
    font-size: 20px;
    width: 80%;
    margin-top: 44px;
    text-decoration: none;
    padding-bottom: 10%;
}
a > .eachNews > .textPartNews >h2{
    text-decoration: none;
}


.textPartNewsNoImage{
    margin: 5% 10% 5% 10%;
    width: auto;
    max-width: 80%;
}
.textPartNewsNoImage> h2{
    font-size: 50px;
    text-decoration: none;
    }
    .textPartNewsNoImage > p{
        font-size: 20px;
        width: 80%;
        margin-top: 44px;
        text-decoration: none;
        padding-bottom: 10%;
    }
    a > .eachNews > .textPartNewsNoImage >h2{
        text-decoration: none;
    }
	
.allNews p, .textPartNews p{
  margin: 1em 0;
}


@media only screen and (max-width: 1600px){
    .textPartNews > p{
        font-size: 20px;
    }
    .textPartNewsNoImage > p{
        font-size: 20px;
    }
}
@media only screen and (max-width: 1430px){
    .textPartNews > h2{
        font-size: 60px;
        }
        .textPartNews > p{
            font-size: 15px;
        }
        .textPartNewsNoImage > h2{
            font-size: 60px;
            }

        .textPartNewsNoImage > p{
            font-size: 15px;
        }
}
@media only screen and (max-width: 1000px){
    .eachNews{
        display: block;
        padding: 10%;
    }
    .textPartNews{
        margin-top: 5%;
        width: auto;
        max-width: 100%;
    }
    .imagePartNews{    
        margin: 0;
        width: 100%;
        height: 40vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .textPartNews > p{
            font-size: 20px;
            width: 100%;
    }
    .textPartNewsNoImage > p{
        font-size: 20px;
        width: 100%;
}
    .eachNews > .imagePartNews > .icons{
        margin-top: 50%;
        margin-left: 2%;
    }
}
@media only screen and (max-width: 700px){
        .news > h1{
        padding-top: 120px;
        padding-bottom: 50px;
        font-size: 40px;
        font-weight: normal;
        
        }
        .textPartNews > h2{
            margin-top: 10%;
            font-size: 30px;
            }
        .textPartNews > p{
            margin-top: 22px;
                font-size: 15px;
                width: 100%;
        }
        .news{
            padding: 0 30px;
        }
    
}