.footer{
    background-color: #1A1A1A;
}
.mainFooterContent{
    display: flex;
    justify-content: space-between ;
    padding-top: 5%;
    width: 100%;
}
.mainFooterContent p, address{
    font-size: 20px;
}
.mainFooterContent h2{
    font-size: 35px;
    padding-bottom: 26px;
}
.aboutUsFooter{
    width: 25%;
    margin-left: 5%;
    padding-right: 5%;
 
}
.contactUsFooter{
margin-right: 13%;
}

.address{
margin-right: 10%
}

.contacts{
    display: flex;
    justify-content: start;
}
.eachContact:nth-child(1){
 margin-right: 5%;
}
.copyright{
    text-align: center;
}

@media only screen and (max-width: 1400px){
    .mainFooterContent p, address{
        font-size: 15px;
    }
    .mainFooterContent h2{
        font-size: 30px;
        padding-bottom: 26px;
    }

}
@media only screen and (max-width: 1000px){
    .contacts{
        display: block;
    }
    .eachContact:nth-child(1){
        margin-right: 0%;
        margin-bottom: 10%;
       }
}

@media only screen and (max-width: 700px){
    .mainFooterContent{
        display: block;
        margin: auto;
        width: 80%;
        margin-top: 10%;
    }
    .mainFooterContent p, address{
        font-size: 15px;
    }
    .mainFooterContent h2{
        font-size: 25px;
        padding-bottom: 26px;
    }
    .aboutUsFooter{
        width: 100%;
        margin: 15% 0;
        padding-right: 0%;
     
    }
    .contactUsFooter{
        margin-right: 0%;
        margin-bottom: 15%;
    }
    .address{
        margin-right: 0px;
        }
        

}
