.getInTouch{
    position: absolute;
    top: 200px;
    z-index: 999999;
    background-color: #242424;
    display: inline-block;
    width: 500px;
    padding-left: 3%;
    padding-top: 35px;
    -webkit-box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
-moz-box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
    
}
.getInTouch > h1{
font-weight: lighter;
display: inline-block;
padding-bottom: 50px;
}
.getInTouch > span{
    display: inline-block;
    float: right;
    color: #707070;
    margin-right: 20px;
    font-size: 30px;
    cursor: pointer;
    
}
.eachPersonContact{
display: flex;
padding-bottom: 30px;
}
.eachPersonContact > .personInfo > p{
    width: 100%;
    padding-right: 100px;
    font-size: 20px;
    padding-bottom: 17px;
}
.eachPersonContact > .personInfo > p > span{
    width: 100%;
    padding-right: 30px;
    font-size: 30px;
    padding-bottom: 17px;
}
.eachPersonContact > .contactInfo > p{
    font-size: 20px;
    padding-bottom: 17px;
}
.eachPersonContact > .contactInfo > p > img{
    width: 10%;
    margin-bottom: -8px;
    margin-right: 10px;

}

/*HIDDEN STATE*/

.getInTouchHidden{
    position: absolute;
    top: 200px;
    z-index: 999999;
    background-color: #242424;
    display: inline-block;
    width: 15px;
    height: 100px;
    padding-left: 3%;
    padding-top: 35px;
    -webkit-box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
-moz-box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
box-shadow: -5px 10px 30px 0px rgba(0,0,0,0.7);
}
.getInTouchHidden > span{
    display: inline-block;
    float: right;
    color: #707070;
    margin-top: -30px;
    margin-right: 150%;
    font-size: 30px;
    cursor: pointer;
}
.getInTouchHidden > h1{
display: none;
}
.getInTouchHidden > .eachPersonContact{
display: none;
}
.hiddenIconMail{
    width: 150%;
    margin-left: -200%;
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 25px;
}
.hiddenIconPhone{
    width: 150%;
    margin-left: -150%;
    margin-top: 50px;
    font-size: 25px;
}
@media only screen and (max-width: 1500px){
    .getInTouchHidden > span{
        margin-right: 100%;
        font-size: 25px;
}
.hiddenIconMail{
    margin-left: -20px;
}
.hiddenIconPhone{
    width: 150%;
    margin-left: -20px;
}
}

@media only screen and (max-width: 1000px){
    .getInTouch{
        width: 400px;
    }
    .getInTouchHidden{
        width: 30px;
    }
    .getInTouchHidden > span{
        display: inline-block;
        float: right;
        margin-top: -30px;
        margin-right: 50%;
        margin-left: 10px;
        font-size: 20px;
    }
    
    .hiddenIconMail{
        width: 50%;
        margin-left: -5px;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .hiddenIconPhone{
        width: 50%;
        margin-left: -15px;
        margin-top: 50px;
    }

    .eachPersonContact > .personInfo > p > span{
        width: 100%;
        padding-right: 10px;
        font-size: 20px;
        padding-bottom: 17px;
    }
    .eachPersonContact > .personInfo > p{
        width: 100%;
        padding-right: 100px;
        font-size: 15px;
        padding-bottom: 17px;
    }
}